import {
	CheckboxField,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeading,
	DrawerProps,
	H4,
	Id,
	LaravelResponse,
	Loading,
	SubmitButton,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { productDeploymentKeys } from "~shared/api/product_deployment";
import { IProductAddonResource } from "~shared/types/product";

interface Props {
	productDeploymentId: Id;
}

interface ContentProps {
	productDeploymentId: Id;
	onClose: DrawerProps["onClose"];
}
export const AddonManagementDrawer: FC<DrawerProps & Props> = ({ productDeploymentId, ...drawerProps }) => {
	const { t } = useTranslation();

	return (
		<Drawer {...drawerProps}>
			<DrawerHeader onClose={drawerProps.onClose}>
				<DrawerHeading>{t("Manage addons")}</DrawerHeading>
			</DrawerHeader>
			<AddonManagementDrawerContent productDeploymentId={productDeploymentId} onClose={drawerProps.onClose} />
		</Drawer>
	);
};

const Headline = styled(H4)`
	margin-bottom: 10px;
`;

const AddonManagementDrawerContent: FC<ContentProps> = ({ productDeploymentId, onClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { showToast } = useToasts();
	const { data: addons, isLoading } = useQuery({
		queryKey: ["products", productDeploymentId, "addons"],
		queryFn: () =>
			axios
				.get<LaravelResponse<IProductAddonResource>>(`products/${productDeploymentId}/addons`)
				.then((r) => r.data.data),
	});

	const handleSubmit = useCallback(
		async (addons: IProductAddonResource) => {
			const response = await axios.put(`products/${productDeploymentId}/addons`, addons);
			if (apiIsOK(response)) {
				void queryClient.invalidateQueries({ queryKey: ["products", productDeploymentId, "addons"] });
				void queryClient.invalidateQueries({ queryKey: productDeploymentKeys.index });
				showToast({
					body: t("Addons were updated."),
					title: t("Deployment"),
					type: "success",
				});
				onClose?.();
			}
		},
		[productDeploymentId, showToast, onClose, queryClient],
	);

	return (
		<DrawerBody>
			{isLoading && <Loading />}
			{addons && (
				<Formik initialValues={addons} onSubmit={handleSubmit}>
					<Form>
						<Headline>PM Addons</Headline>
						{addons.pmAddons.map((addon, index) => (
							<CheckboxField key={addon.addon} name={`pmAddons.${index}.isEnabled`} label={addon.addon} />
						))}
						<Headline>DEEP Addons</Headline>
						{addons.deepAddons.map((addon, index) => (
							<CheckboxField key={addon.addon} name={`deepAddons.${index}.isEnabled`} label={addon.addon} />
						))}
						<SubmitButton>{t("Submit")}</SubmitButton>
					</Form>
				</Formik>
			)}
		</DrawerBody>
	);
};
