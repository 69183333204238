import { Id, Loading } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { useProductLicenceCheckQuery } from "~shared/api/product/product_queries";

interface Props {
	productId: Id;
	numberOfParticipants: number | "unknown";
}

const Count = styled.span<{ $isExceeded: boolean }>`
	color: ${({ theme, $isExceeded }) => ($isExceeded ? theme.colors.palette.danger.main.base : "inherit")};
`;
export const SystemInfo: FC<Props> = ({ productId, numberOfParticipants }) => {
	const { data, isLoading } = useProductLicenceCheckQuery(productId);
	if (isLoading) {
		return <Loading variant="small" />;
	}
	if (!data) {
		return null;
	}
	return (
		<>
			<span>
				<b>Version</b>: {data.version}
				<br />
				Registerd:{" "}
				<Count $isExceeded={data.registeredParticipants > numberOfParticipants}>
					{data.registeredParticipants} / {numberOfParticipants}
				</Count>
				<br />
				Total: {data.numberOfParticipants}
				<br />
				Deleted: {data.deletedParticipants}
				<br />
				Enabled Addons: {data.enabledAddons.join(", ")}
			</span>
		</>
	);
};
